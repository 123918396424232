<template>
  <div>
    <b-sidebar
      id="sidebar-add-site-project"
      ref="sidebar_add_site_project"
      :visible="showOpenAddSiteProject"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-add-site-project', val)"
    >
      <template #default="{ hide }">
        <b-overlay
          :show="loader"
          rounded="xl"
          no-fade
        >
          <div :aria-hidden="loader ? 'true' : null">
            <div
              class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50 mt-2"
              style="height: 91px; background: #fff;"
            >
              <b-card
                no-body
                class="overflow-hidden w-100"
                style=""
              >
                <header-sidebar :idsite-header.sync="idsite" />
              </b-card>
              <feather-icon
                class="cursor-pointer"
                icon="XIcon"
                size="20"
                @click="hide"
              />
            </div>
            <h3
              class="mb-0 pb-0 pl-2 mt-50"
              style="font-weight: 700; color: #181E38 !important; padding: 20px;"
            >
              Add Site
            </h3>
            <div
              class="b-sidebar-body mt-1"
              style="padding: 40px 40px 0px 40px;"
              v-if="existingSite.length"
            >
              <h3
                class="mb-0 pb-0 mt-25"
                style="font-weight: 700; color: #656B85 !important; font-size: 16px;"
              >
                Site added
              </h3>
              <div
                style="max-height: calc(100vh - 478px); overflow-y: auto;"
              >
                <b-list-group
                  v-for="(project, index) in existingSite"
                  :key="index"
                  flush
                >
                  <b-list-group-item
                    class="cursor-pointer"
                  >
                    <b-button
                      style="background: #7838ff !important; border: 1px solid #7838ff !important; width: 108px; height: 36px; border-radius: 15px; float: right; font-weight: 700; color: #FFFFFF;"
                      class=""
                      variant="primary"
                      @click="viewSite(project)"
                    >
                    View site
                    </b-button>
                    <span
                      class="mr-50"
                      style="font-weight: 700; color: #181E38; font-size: 16px; display: flex; margin-top: 6px;"
                    >
                      <div
                        :style="`width: 16px; height: 16px; border-radius: 4px; background: ${project.color}; margin-top: 6px;`"
                        class="mr-50"
                      />
                      <span>{{ project.label }} <span style="color: #7838ff !important;" v-if="project.owner === 'shared'">(Shared access)</span></span>
                    </span>
                  </b-list-group-item>
                  <hr class="mt-0 mb-0">
                </b-list-group>
              </div>
            </div>
            <div
              class="b-sidebar-body mt-25"
              style="padding: 40px;"
              v-else
            >
              <h3
                :class="existingSite.length ? 'mb-0 pb-0 mt-0' : 'mb-0 pb-0 mt-3'"
                style="font-weight: 700; color: #656B85 !important; font-size: 16px;"
              >
                Select destination project
              </h3>
              <div
                style="max-height: calc(100vh - 478px); overflow-y: auto;"
                v-if="!loader"
              >
                <b-list-group
                  v-for="(project, index) in allProject"
                  :key="index"
                  flush
                >
                  <b-list-group-item
                    class="cursor-pointer"
                    @click.stop="selectedMoveFunction(project.label)"
                  >
                    <span
                      class="mr-50 mt-1"
                      style="font-weight: 700; color: #181E38; font-size: 16px; display: flex; margin-top: 0px;"
                    >
                      <b-form-checkbox
                        @change="selectedMoveFunction(project.label)"
                        v-model="selectedMove"
                        :value="project.label"
                        class="custom-control-primary mt-25"
                        style="margin-right: 6px;"
                      />
                      <div
                        :style="`width: 16px; height: 16px; border-radius: 4px; background: ${project.color}; margin-top: 6px;`"
                        class="mr-50"
                        @click.stop="selectedMoveFunction(project.label)"
                      />
                      <span @click.stop="selectedMoveFunction(project.label)">{{ project.label }} <span style="color: #7838ff !important;" v-if="project.owner === 'shared'">(Shared access)</span></span>
                    </span>
                  </b-list-group-item>
                  <hr class="mt-0 mb-0">
                </b-list-group>
              </div>
              <b-button
                style="background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 15px; margin-right: 40px;"
                class="mt-4"
                variant="primary"
                :disabled="loadingSubmit"
                @click="submitEditar"
                v-if="!loader"
              >
                <span
                  class="align-middle"
                  style="font-weight: 700; color: #FFFFFF;"
                >Add site</span>
              </b-button>
            </div>
          </div>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BOverlay,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import ClaimOwnership from '@/views/apps/sites/sites-add/ClaimOwnership.vue'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db, auth, firebase } from '@/firebase'
import { counter } from '@fortawesome/fontawesome-svg-core'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BOverlay,
    ClaimOwnership,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenAddSiteProject',
    event: 'update:show-open-add-site-project',
  },
  props: ['idsite', 'showOpenAddSiteProject'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      selectedMove: '',
      loader: true,
      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      permissionsOptions: ['clinician viewer', 'manage users', 'data specialist'],
      allProject: [],
      loadingSubmit: false,
      localIdsite: '',
      existingSite: [],
    }
  },
  watch: {
    idsite(val) {
    //   console.log(val)
    },
    showOpenAddSiteProject(val) {
      this.loader = true
      const user = auth.currentUser
      // console.log(user.uid)
      this.existingSite = []
      
      if(val) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
        localStorage.removeItem('configCrawlAddSite')
        localStorage.removeItem('configCrawlViewSite')
      }
      
      if(user && val) {
        // console.log(this.idsite)
        if(!this.idsite) {
            // console.log(this.$route.query.site)
            db.collection('Sites').where('WebsiteURL', '==', this.$route.query.site).limit(1).get()
            .then(siteQueryWebsite => {
                if(siteQueryWebsite.size) {
                    siteQueryWebsite.forEach(site => {
                        var projectWebsite = site.data().projects[0]
                        this.localIdsite = {
                            imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                            name: site.data().name ? site.data().name : site.data().homepage,
                            domain: site.data().homepage,
                            currentErrors: '267',
                            fixedIssues: '79',
                            key: site.id,
                            alerts: '3',
                            membership: `${site.data().plan ? site.data().plan : 'Free'} license`,
                            ownedBy: site.data().owner && site.data().owner.uid === user.uid ? site.data().owner : {},
                            owner: 'me',
                            label: '',
                            color: '',
                            id: site.id,
                            projectid: projectWebsite
                        }
                        // this.$emit('update:idsite', idsiteAtom)
                        // this.$emit('idsite', idsiteAtom)

                        this.userData = ''
                        this.allProject = []
                        db.collection('Users').doc(user.uid).get()
                        .then(userDoc => {
                            var userData = {}
                            userData.uid = userDoc.data().uid
                            userData.foto = userDoc.data().foto ? userDoc.data().foto : ''
                            userData.name = userDoc.data().nombre ? userDoc.data().nombre : ''
                            userData.status = userDoc.data().status ? userDoc.data().status : ''
                            userData.email = userDoc.data().email ? userDoc.data().email : ''
                            this.userData = userData
                            db.collection('Users').doc(user.uid).collection('projects').get()
                            .then(userDocProjects => {
                                userDocProjects.forEach(eleUserProject => {
                                  var filterProj = this.allProject.filter(ele => ele.uid === eleUserProject.data().uid)
                                  if(!filterProj.length) {
                                      // if(eleUserProject.data().id !== this.idsite.projectid) {
                                      if(eleUserProject.data().name === 'My sites') {
                                          this.allProject.unshift({
                                            label: eleUserProject.data().name,
                                            name: eleUserProject.data().name ? eleUserProject.data().name.toLowerCase() : '',
                                            owner: 'me',
                                            color: eleUserProject.data().color,
                                            uid: eleUserProject.data().id,
                                          })
                                      } else {
                                          this.allProject.push({
                                          label: eleUserProject.data().name,
                                          name: eleUserProject.data().name ? eleUserProject.data().name.toLowerCase() : '',
                                          owner: 'me',
                                          color: eleUserProject.data().color,
                                          uid: eleUserProject.data().id,
                                          })

                                          var condicionParaOrdenarAlpha = (pageA, pageB) => {
                                            if(pageA.name === 'my sites') {
                                              return -1
                                            }
                                            if(pageB.name === 'my sites') {
                                              return 1
                                            }
                                            
                                            return pageA.name.toLowerCase().localeCompare(pageB.name.toLowerCase())
                                          }
                                          this.allProject.sort(condicionParaOrdenarAlpha)
                                      }
                                      // }
                                  }
                                })

                                db.collection('Sites').where('WebsiteURL', '==', site.data().WebsiteURL).where('teamMembers', 'array-contains', user.uid).get()
                                .then((querySite) => {
                                  if(querySite.size) {
                                    this.existingSite = []
                                    var count = 0
                                    querySite.forEach(ele => {
                                      if(count === 0) {
                                        count += 1
                                        var projects = ele.data().projects ? ele.data().projects : []
                                        var filterProjects = this.allProject.filter(item => projects.includes(item.uid))
                                        if(filterProjects.length) {
                                          filterProjects.forEach(project => {
                                            var obj = project
                                            obj.id = ele.id
                                            obj.imgSite = ele.data().thumbnail
                                            obj.name = ele.data().name ? ele.data().name.toLowerCase() : ''
                                            obj.url = ele.data().WebsiteURL
                                            obj.project = project.uid
                                            obj.own = 'me'
                                            this.existingSite.push(obj)
                                          })
                                        }
                                      }
                                    })
                                    this.loader = false
                                  } else {
                                    this.loader = false
                                  }
                                })
                            })

                            // this.sortProjects()
                            this.selectedMove = this.localIdsite.label ? this.localIdsite.label : 'My sites'
                        })
                    })
                }
            })
        } else {
          // console.log(user.uid)
            this.userData = ''
            this.allProject = []
            db.collection('Users').doc(user.uid).get()
            .then(userDoc => {
                var userData = {}
                userData.uid = userDoc.data().uid
                userData.foto = userDoc.data().foto ? userDoc.data().foto : ''
                userData.name = userDoc.data().nombre ? userDoc.data().nombre : ''
                userData.status = userDoc.data().status ? userDoc.data().status : ''
                userData.email = userDoc.data().email ? userDoc.data().email : ''
                this.userData = userData
                db.collection('Users').doc(user.uid).collection('projects').get()
                .then(userDocProjects => {
                    userDocProjects.forEach(eleUserProject => {
                      var filterProj = this.allProject.filter(ele => ele.uid === eleUserProject.data().uid)
                      if(!filterProj.length) {
                          // if(eleUserProject.data().id !== this.idsite.projectid) {
                          if(eleUserProject.data().name === 'My sites') {
                              this.allProject.unshift({
                              label: eleUserProject.data().name,
                              name: eleUserProject.data().name ? eleUserProject.data().name.toLowerCase() : '',
                              owner: 'me',
                              color: eleUserProject.data().color,
                              uid: eleUserProject.data().id,
                              })
                          } else {
                              this.allProject.push({
                              label: eleUserProject.data().name,
                              name: eleUserProject.data().name ? eleUserProject.data().name.toLowerCase() : '',
                              owner: 'me',
                              color: eleUserProject.data().color,
                              uid: eleUserProject.data().id,
                              })

                              var condicionParaOrdenarAlpha = (pageA, pageB) => {
                                if(pageA.name === 'my sites') {
                                  return -1
                                }
                                if(pageB.name === 'my sites') {
                                  return 1
                                }
                                
                                return pageA.name.toLowerCase().localeCompare(pageB.name.toLowerCase())
                              }
                              this.allProject.sort(condicionParaOrdenarAlpha)
                          }
                          // }
                      }
                    })

                    db.collection('Sites').where('WebsiteURL', '==', this.$route.query.site).where('teamMembers', 'array-contains', user.uid).get()
                    .then((querySite) => {
                      if(querySite.size) {
                        this.existingSite = []
                        var count = 0
                        querySite.forEach(ele => {
                          if(count === 0) {
                            count += 1
                            var projects = ele.data().projects ? ele.data().projects : []
                            var filterProjects = this.allProject.filter(item => projects.includes(item.uid))
                            if(filterProjects.length) {
                              filterProjects.forEach(project => {
                                var obj = project
                                obj.id = ele.id
                                obj.imgSite = ele.data().thumbnail
                                obj.name = ele.data().name ? ele.data().name.toLowerCase() : ''
                                obj.url = ele.data().WebsiteURL
                                obj.project = project.uid
                                obj.own = 'me'
                                this.existingSite.push(obj)
                              })
                            }
                          }
                        })
                        this.loader = false
                      } else {
                        this.loader = false
                      }
                    })
                })

                // this.sortProjects()
                this.selectedMove = this.idsite.label ? this.idsite.label : 'My sites'
            })
        }
      }
    },
  },
  validations: {
  },
  methods: {
    viewSite(payload) {
      this.$router.push({
        name: 'view-errors',
        params: {
          id: payload.id, imgSite: payload.imgSite, name: payload.name ? payload.name.toLowerCase() : '', url: payload.url, project: payload.project, own: 'me'
        },
      })

      var crawlSettView = localStorage.getItem('configCrawlViewSite')
      if(crawlSettView) {
        localStorage.removeItem('configCrawlViewSite')
        localStorage.setItem('configRunLicenseOwner', 'run')
        document.body.style.overflow = 'auto'
      }
      
      var crawlSett = localStorage.getItem('configCrawlAddSite')
      if(crawlSett) {
        localStorage.removeItem('configCrawlAddSite')
        localStorage.setItem('runCrawlConfig', 'run')
      }
      this.loadingSubmit = false
    },
    sortProjects() {
      this.allProject.sort(function (a, b) {
        if(a.name > b.name) {
          return 1;
        }
        if(a.name < b.name) {
          return -1;
        }
        return 0;
      });

      setTimeout(() => {
        this.loader = false
      }, 1500)
    },
    submitEditar() {
      if(this.selectedMove) {
        let projectSelected = ''
        projectSelected = this.allProject.filter(proj => proj.label === this.selectedMove)
        if(projectSelected.length >= 1 && projectSelected[0].uid) {
          // console.log(projectSelected[0])
          const user = auth.currentUser
          this.loadingSubmit = true

          if(this.idsite && this.idsite.id) {
            db.collection('Sites').doc(this.idsite.id).get()
            .then(docSite => {
              if(docSite.exists) {
                db.collection('Sites').where('WebsiteURL', '==', docSite.data().WebsiteURL).where('teamMembers', 'array-contains', user.uid).get()
                .then((querySite) => {
                  if(querySite.size === 0) {
                    db.collection('Projects').doc(projectSelected[0].uid).collection('sites').where('WebsiteURL', '==', docSite.data().WebsiteURL).get()
                    .then(queryProjectSite => {
                      if(queryProjectSite.size === 0) {
                        // set item in array projects sites
                        db.collection('Sites').doc(this.idsite.id).update({
                          projects: firebase.firestore.FieldValue.arrayUnion(projectSelected[0].uid),
                          teamMembers: firebase.firestore.FieldValue.arrayUnion(user.uid)
                        })
                        db.collection('Sites_cache').doc(this.idsite.id).update({
                          projects: firebase.firestore.FieldValue.arrayUnion(projectSelected[0].uid)
                        })

                        // set site in moved project
                        db.collection('Projects').doc(projectSelected[0].uid).collection('sites').doc(docSite.id).set({
                          SiteID: docSite.id,
                          WebsiteURL: docSite.data().WebsiteURL,
                          homepage: docSite.data().homepage,
                          name: docSite.data().name,
                          n: docSite.data().n,
                          thumbnail: docSite.data().thumbnail,
                          totalErrors: docSite.data().totalErrors,
                          plan: docSite.data().plan ? docSite.data().plan : 'Free',
                          paidRecurrence: docSite.data().paidRecurrence,
                          claimed: docSite.data().claimed,
                          owner: docSite.data().owner,
                          status: 'active',
                        })

                        // update project moved user data
                        db.collection('Users').doc(user.uid).collection('projects').doc(projectSelected[0].uid).get()
                        .then(userP => {
                          db.collection('Users').doc(user.uid).collection('projects').doc(projectSelected[0].uid).update({
                            numberSites: userP.data().numberSites + 1
                          })
                        })

                        setTimeout(() => {
                          db.collection('Users').doc(user.uid).update({
                            updated: Date.now(),
                          })

                          this.$router.push({
                            name: 'view-errors',
                            params: {
                              id: docSite.id, imgSite: docSite.data().thumbnail, name: docSite.data().name ? docSite.data().name.toLowerCase() : '', url: docSite.data().WebsiteURL, project: projectSelected[0].uid, own: 'me'
                            },
                          })

                          this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                              title: 'Site added',
                              icon: 'BellIcon',
                              variant: 'success',
                            },
                          })
                          localStorage.setItem('runCrawlConfig', 'run')
                          this.loadingSubmit = false
                          this.$refs.sidebar_add_site_project.hide()
                        }, 1500)
                      } else {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'Site already exist in project',
                            icon: 'BellIcon',
                            variant: 'warning',
                          },
                        })
                        this.loadingSubmit = false
                      }
                    })
                  } else {
                    this.existingSite = []
                    var count = 0
                    querySite.forEach(ele => {
                      if(count === 0) {
                        count += 1
                        var projects = ele.data().projects ? ele.data().projects : []
                        var filterProjects = this.allProject.filter(item => projects.includes(item.uid))
                        if(filterProjects.length) {
                          filterProjects.forEach(project => {
                            var obj = project
                            obj.id = ele.id
                            obj.imgSite = ele.data().thumbnail
                            obj.name = ele.data().name ? ele.data().name.toLowerCase() : ''
                            obj.url = ele.data().WebsiteURL
                            obj.project = project.uid
                            obj.own = 'me'
                            this.existingSite.push(obj)
                          })
                        }
                      }
                    })

                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `The site has been added to one of your projects`,
                        icon: 'CoffeeIcon',
                        variant: 'warning'
                      },
                    })
                    this.loadingSubmit = false
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error adding site, please try again later',
                    icon: 'BellIcon',
                    variant: 'warning',
                  },
                })
                this.loadingSubmit = false
              }
            })
          } else {
              db.collection('Sites').doc(this.localIdsite.id).get()
              .then(docSite => {
                if(docSite.exists) {
                  db.collection('Sites').where('WebsiteURL', '==', docSite.data().WebsiteURL).where('teamMembers', 'array-contains', user.uid).get()
                  .then((querySite) => {
                    if(querySite.size === 0) {
                      db.collection('Projects').doc(projectSelected[0].uid).collection('sites').where('WebsiteURL', '==', docSite.data().WebsiteURL).get()
                      .then(queryProjectSite => {
                        if(queryProjectSite.size === 0) {
                          // set item in array projects sites
                          db.collection('Sites').doc(this.localIdsite.id).update({
                            projects: firebase.firestore.FieldValue.arrayUnion(projectSelected[0].uid),
                            teamMembers: firebase.firestore.FieldValue.arrayUnion(user.uid)
                          })
                          db.collection('Sites_cache').doc(this.localIdsite.id).update({
                            projects: firebase.firestore.FieldValue.arrayUnion(projectSelected[0].uid)
                          })

                          // set site in moved project
                          db.collection('Projects').doc(projectSelected[0].uid).collection('sites').doc(docSite.id).set({
                            SiteID: docSite.id,
                            WebsiteURL: docSite.data().WebsiteURL,
                            homepage: docSite.data().homepage,
                            name: docSite.data().name,
                            n: docSite.data().n,
                            thumbnail: docSite.data().thumbnail,
                            totalErrors: docSite.data().totalErrors,
                            plan: docSite.data().plan ? docSite.data().plan : 'Free',
                            paidRecurrence: docSite.data().paidRecurrence,
                            claimed: docSite.data().claimed,
                            owner: docSite.data().owner,
                            status: 'active',
                          })

                          // update project moved user data
                          db.collection('Users').doc(user.uid).collection('projects').doc(projectSelected[0].uid).get()
                          .then(userP => {
                            db.collection('Users').doc(user.uid).collection('projects').doc(projectSelected[0].uid).update({
                              numberSites: userP.data().numberSites + 1
                            })
                          })

                          setTimeout(() => {
                            db.collection('Users').doc(user.uid).update({
                              updated: Date.now(),
                            })

                            this.$router.push({
                              name: 'view-errors',
                              params: {
                                id: docSite.id, imgSite: docSite.data().thumbnail, name: docSite.data().name ? docSite.data().name.toLowerCase() : '', url: docSite.data().WebsiteURL, project: projectSelected[0].uid, own: 'me'
                              },
                            })

                            this.$toast({
                              component: ToastificationContent,
                              position: 'top-right',
                              props: {
                                title: 'Site added',
                                icon: 'BellIcon',
                                variant: 'success',
                              },
                            })
                            localStorage.setItem('runCrawlConfig', 'run')
                            this.loadingSubmit = false
                            this.$refs.sidebar_add_site_project.hide()
                          }, 1500)
                        } else {
                          this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                              title: 'Site already exist in project',
                              icon: 'BellIcon',
                              variant: 'warning',
                            },
                          })
                          this.loadingSubmit = false
                        }
                      })
                    } else {
                      this.existingSite = []
                      var count = 0
                      querySite.forEach(ele => {
                        if(count === 0) {
                          count += 1
                          var projects = ele.data().projects ? ele.data().projects : []
                          var filterProjects = this.allProject.filter(item => projects.includes(item.uid))
                          if(filterProjects.length) {
                            filterProjects.forEach(project => {
                              var obj = project
                              obj.id = ele.id
                              obj.imgSite = ele.data().thumbnail
                              obj.name = ele.data().name ? ele.data().name.toLowerCase() : ''
                              obj.url = ele.data().WebsiteURL
                              obj.project = project.uid
                              obj.own = 'me'
                              this.existingSite.push(obj)
                            })
                          }
                        }
                      })

                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `The site has been added to one of your projects`,
                          icon: 'CoffeeIcon',
                          variant: 'warning'
                        },
                      })
                      this.loadingSubmit = false
                    }
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error adding site, please try again later',
                      icon: 'BellIcon',
                      variant: 'warning',
                    },
                  })
                  this.loadingSubmit = false
                }
              })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'No site chosen',
              icon: 'BellIcon',
              variant: 'warning',
            },
          })
          this.loadingSubmit = false
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No site chosen',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
        this.loadingSubmit = false
      }
    },
    selectedMoveFunction(payload) {
      this.selectedMove = payload
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-add-site-project {
    width: 90%;
    max-width: 593px !important;
}
</style>